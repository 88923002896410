import React, { HTMLInputTypeAttribute } from "react";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id?: string;
  label: string;
  type: string;
  defaultValue?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export default function Input(props: Props) {
  const {
    name,
    label,
    type,
    id,
    required,
    onChange,
    defaultValue,
    onBlur,
    onFocus,
  } = props;
  return (
    <div>
      {required ? (
        <>
          <label
            htmlFor={name}
            className="required-field block text-sm font-medium text-gray-700"
          >
            {label}
          </label>

          <div className="mt-1">
            <input
              onBlur={onBlur}
              onFocus={onFocus}
              onChange={onChange ? (e) => onChange(e) : () => {}}
              required
              defaultValue={defaultValue}
              id={id}
              className="read-only:bg-gray-200 focus:ring-blue-500  block w-full sm:text-sm  rounded-md ring-offset-gray-900/20 border-0 ring-1 ring-gray-900/20 "
              {...props}
            />{" "}
          </div>
        </>
      ) : (
        <>
          <label
            htmlFor={name}
            className=" block text-sm font-medium text-gray-700"
          >
            {label}
          </label>
          <div className="mt-1">
            <input
              onFocus={onFocus}
              onBlur={onBlur ? onBlur : () => {}}
              defaultValue={defaultValue}
              onChange={onChange ? (e) => onChange(e) : () => {}}
              id={id}
              className="read-only:bg-gray-200 focus:ring-blue-500 block w-full sm:text-sm rounded-md border-0 ring-1 ring-offset-gray-900/20 ring-gray-900/20 "
              {...props}
            />
          </div>
        </>
      )}
    </div>
  );
}
